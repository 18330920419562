import './App.css';
import { Route, Routes} from 'react-router-dom';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Games from './pages/games/Games';
import Policy from './pages/policy/Policy';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={ <Home /> }></Route>
        <Route exact path="/about" element={ <About /> }></Route>
        <Route exact path='/contact' element={ <Contact /> }></Route>
        <Route exact path='/games' element={ <Games /> }></Route>
        <Route exact path='/policy' element={ <Policy /> }></Route>
      </Routes>
    </div>
  );
}

export default App;
