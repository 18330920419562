import "./Home.css";
import Navbar from "../../components/navbar/NavBar";
import { Link } from "react-router-dom";

function Home(props) {
    return (
        <div className="home-main-div">
            <Navbar />
            <div className="page-name">
                14Hours Productions
            </div>
            {/* <div className="small-comp">
                a small games studio
            </div> */}
            <br />
            <br />
            <div className="home-holder">
                <div className="latest-news-holder">
                    <div className="latest-news">
                    ₊˚⊹ Now Available:
                    </div>
                    <div className="latest-news-img">
                        <a href="https://14hoursproductions.com/YHPresskit">
                            <img 
                                className="home-img"
                                src="../../images/yolk-hereos.png"
                                alt="Yolk Hereos"
                            />
                        </a>
                    </div>
                    <div className="small-intro">
                        14 Hours Productions is a small games studio that makes small games.
                        <br />
                        If you want big games you should go looking for a big studio.
                        <br />
                        To learn about us and what we do, check out the <Link className="goto-page" to="/about">about page</Link> or our <Link className="goto-page" to='/games'>games</Link>.
                    </div>
                </div>
            
                <div className="other-news-holder">
                    <div className="other-news-header">
                        ₊˚⊹ Cool News:
                    </div>
                    <div className="other-news">
                        No news yet, stay tuned! ✧.*
                    </div>
                    <div className="other-news-img">
                        <img 
                            className="home-gif-1"
                            src="../../images/crowd_cheer_v2.gif"
                            alt="Crowd Cheer"
                        />
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default Home;