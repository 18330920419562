import "./NavBar.css";
import { Link } from "react-router-dom";

function Navbar(props) {
    console.log(window.location.pathname);
    return (
        <div className="navbar">
            <div className="bg-img-nav">
                <img
                    className="logo-color"
                    src="../../images/14HoursLogoColor.png"
                    alt="Logo Colored"
                />
                 {/* <img 
                    className="grass-gif"
                    src="../../images/bkg2_prop2.gif"
                    alt="Grass"
                /> */}
                <img 
                    className="skull-gif"
                    src="../../images/bkg2_prop1.gif"
                    alt="Skull"
                />
            </div>
            <div className="nav-holder">
                <Link className={"nav " + (window.location.pathname === '/' ? 'selected-nav-item' : '')} to="/">
                    <p>
                        home
                    </p>
                </Link>
                <Link className={"nav " + (window.location.pathname.includes('/about') ? 'selected-nav-item' : '')} to="/about">
                    <p>
                        about
                    </p>
                </Link>
                <Link className={"nav " + (window.location.pathname.includes('/contact') ? 'selected-nav-item' : '')} to="/contact">
                    <p>
                        contact
                    </p>
                </Link>
                <Link className={"nav " + (window.location.pathname.includes('/games') ? 'selected-nav-item' : '')} to="/games">
                    <p>
                        games
                    </p>
                </Link>
                <Link className={"nav " + (window.location.pathname.includes('/policy') ? 'selected-nav-item' : '')} to="/policy">
                    <p>
                        policy
                    </p>
                </Link>
            </div>
        </div>
    )
}

export default Navbar;