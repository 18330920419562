import Navbar from "../../components/navbar/NavBar";
import "./Policy.css";

function Policy(props) {
    return (
        <div className="policy-main-div">
            <Navbar />
            <div className="page-name-test">
                Policy
            </div>
            <div className="policy-holder">
                <div className="policy-headers">
                    Introduction:
                </div>
                <div className="policy-info">
                    Thank you for choosing to play Yolk Heroes: 
                    A Long Tamago. Your privacy is important to us. 
                    This Privacy Policy explains how we collect, use, disclose, 
                    and safeguard your information when you use our game on iPhone, Android, and PC platforms. 
                    Please read this policy carefully. By accessing or using the game, you agree to the terms of this Privacy Policy.
                </div>
                <div className="policy-headers">
                    Information We DO NOT Collect:
                </div>
                <div className="policy-info">
                    No Personal Data <br />
                    Our game does not collect any personal data from users. 
                    This includes but is not limited to names, addresses, email addresses, phone numbers, or any other personal identifiers.
                    <br /><br/><br />
                    No Server Communication <br />
                    Our game operates entirely offline and does not require or perform any communication with external servers.
                    There is no data transmission to or from your device once the game is downloaded.
                    <br /><br/><br />
                    No Backend Storage <br />
                    We do not store any user data on our servers.
                    All game data is stored locally on your device and is not accessible by us.
                </div>
                <div className="policy-headers">
                    Information We Collect:
                </div>
                <div className="policy-info">
                    Local Storage <br />
                    The game may store certain game-related data locally on your device, such as game progress, settings, and preferences.
                    This data is used solely to enhance your gaming experience and is not transmitted or accessible to us.
                    <br /><br/><br />
                    How We Use Information <br />
                    Since we do not collect or store any personal data, we do not use or process any personal information for any purpose.
                    <br /><br/><br />
                    Third-Party Services <br />
                    Our game does not use any third-party services that collect or process personal data.
                    <br /><br/><br />
                    Security <br />
                    While no online service is 100% secure, we take reasonable measures to protect the information stored on your device. 
                    However, we cannot guarantee the absolute security of your local data.
                </div>
                <div className="policy-headers">
                    Children's Privacy:
                </div>
                <div className="policy-info">
                    Our game is designed for a general audience and does not target children under the age of 13. 
                    We do not knowingly collect personal data from children under 13. 
                    If we become aware that we have inadvertently received personal data from a user under the age of 13, we will delete such information from our records.
                </div>
                <div className="policy-headers">
                    Changes to This Policy:
                </div>
                <div className="policy-info">
                    We may update this Privacy Policy from time to time. 
                    If we make any changes, we will notify you by updating the date at the top of this policy. 
                    We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
                </div>
                <div className="policy-headers">
                    Contact Us:
                </div>
                <div className="policy-info">
                    If you have any questions or concerns about this Privacy Policy, please contact us at:
                </div>
                    <br />
                    <br />
                <div className="policy-contact">
                    14 Hours Productions <br />
                    22312 Starling Dr. Los Altos 94024<br />
                    contact@14hoursproductions.com<br />
                </div>
                <br /><br/>
                <div className="policy-info-end">
                    Thank you for playing Yolk Heroes: A long Tamago. <br/>
                    Enjoy your gaming experience with peace of mind knowing that your privacy is our priority.
                </div>

            </div>
        </div>
    )
}

export default Policy;