import "./Games.css";
import Navbar from "../../components/navbar/NavBar";

function Games(props) {

    return (
        <div className="games-main-div">
            <Navbar />
            <div className="page-name-test">
                Games
            </div>
           
            <br />
            <br />
            <br />
            {/* <div className="links-disabled">
                Some links are currently unavailable. Please stay tuned! ✧.*
            </div> */}
            <div className="game-list-holder">
                <a href="https://14hoursproductions.com/YHPresskit" target="_blank" rel="noreferrer">
                  <img 
                    className="yolkhereos-img"
                    src="../../images/yolk-hereos.png"
                    alt="Yolk Hereos"
                  />
                </a>
                <br />
                <br />
                <br />
                <a href="https://pixelwestern.itch.io/dead-horizon" target="_blank" rel="noreferrer">
                    <img 
                        className="deadhorizon-img"
                        src="../../images/dead-horizon.png"
                        alt="Dead Horizon"
                    />
                </a>
                <br />
                <br />
                <br />
                <a href="https://14hoursproductions.com/tyrantulas1/boohill-press.html" target="_blank" rel="noreferrer">
                    <img 
                        className="boonhill-img"
                        src="../../images/boonhill.jpg"
                        alt="Boonhill"
                    />
                </a>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default Games;