import "./About.css";
import Navbar from "../../components/navbar/NavBar";

function About(props) {
    return (
        <div className="about-main-div">
            <Navbar />
            <div className="page-name-test">
                About
            </div>
            <br />
            <br />
            <br />
            <div className="about-all-div">
                <div className="poster-img-div">
                    <img 
                        className="poster-img"
                        src="../../images/poster4x.png"
                        alt="Poster"
                    />
                </div>
                <div className="about-info">
                    <div>
                        14 Hours Productions is more a collection of friends and acquaintances working in their free time to try and make quality products <br /> than an actual studio. 
                        It has no home base of operations because the things like an office cost money. <br />Still, it is operated by dedicated people who have very strong feelings about 
                        game design and the potential for the artistic merit of looking at interactive narrative from different perspectives.
                    </div>
                    <br />
                    <div>
                        Started a few years ago as a pixel art animation studio for other indie game makers and movies it was only a natural progression <br /> to move into making their own
                        original content instead of always making content for others. Whether or not the world will respect <br /> and enjoy that content is yet to be seen.
                    </div>
                    <br />
                    <div>
                        A lot of small game studios normally put something vaguely artistically inspiring about here as a mission statement. 
                        <br />
                        14 Hours hates to buck trends, so here goes...
                    </div>
                    <br />
                    <div>
                        We want to make games people will play. Then we can make more games.
                        <br />
                        That's the core foundation of the 14 Hours Productions philosophy. It is not a complex one, but it is honest.
                    </div>
                    <br />
                    <br />
                    <div>
                        We look foward to you trying our games.
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default About;