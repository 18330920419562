import "./Contact.css";
import Navbar from "../../components/navbar/NavBar";

function Contact(props) {
    return (
        <div className="contact-main-div">
            <Navbar />
            <div className="page-name-test">
                Contact
            </div>
            <br />
            <br />
            <br />
            <div class="contact-all-hr">
                <div className="contact-info-holder">
                    <div className="contact-info-header">
                        ₊˚⊹ Contact Information
                    </div>
                    <br />
                    <div className="contact-info">
                        <div>
                            Feel free to contact us for <i>any</i> reason. <br />
                            If that reason is mean, we can not promise we will get back to you. <br />
                            But we might make fun of you some behind your back.
                        </div>
                        <br />
                        <div>
                            To get a hold of 14 Hours Productions: <br />
                            <div className="email">contact@14hoursproductions.com</div>
                        </div>
                        <br />
                        <div>
                            Or if wanting to send an email relating to boon hill: <br />
                            <div className="email">boonhill@14hoursproductions.com</div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="contact-faq-holder">
                    <div className="contact-faq-header">
                        ₊˚⊹ Frequently Asked Questions
                    </div>
                    <br />
                    <div className="contact-faq">
                        F.A.Qs soon to be added. ✧.*
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default Contact;